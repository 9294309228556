import { functions } from "@/plugins/firebase"
import { httpsCallable } from "firebase/functions"

export default  {
    namespaced: true,
    state: {
        pricehistory: []
        
    },
    getters: {
    },
    mutations: {
        set_pricehistory(state, payload) {
            state.pricehistory = payload
        },
    },
    actions: {
        async getPriceHistory({ commit }, {vertrag}) {
            const getChartData = httpsCallable(functions, "getChartData")
            const data = await getChartData({vertrag: vertrag})
            // state.pricehistory=data.data
            commit ("set_pricehistory", data.data)
        }
    }
}



