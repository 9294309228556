<template>
   <div class="custom-table">
        <h1 style="text-align: center; margin: 10px;">Übersicht OptBuy Pro Limits und StoppLoss</h1>
        
        <div style="display: flex; justify-content: center;">
            <v-card style="width:100%; max-width: 800px;">
                <v-card-title>
                    <h2>Strom</h2>
                </v-card-title>
                <v-card-text>
                    <table style="box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); color: black;">
                        <thead>
                            <tr style="font-size: medium;">
                                <th>Jahr</th>
                                <th>Zeitbereiche</th>
                                <th>Limit</th>
                                <th>Stopploss</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="art in Object.keys(stromLimits)">
                                <tr :key="art">
                                    <td style="border-bottom: 2px solid black; font-size: medium;">{{ art }}</td>
                                    <td style="padding: 0px;">
                                        <template v-for="(subart, index) in Object.keys(stromLimits[art])">
                                            <div :key="subart" 
                                                :style="{ borderBottom: index === Object.keys(stromLimits[art]).length - 1 ? 'none' : '1px solid black', padding: '8px'}"
                                            >
                                                {{ subart }}
                                            </div>
                                        </template>
                                    </td>
                                    <td style="padding: 0px;">
                                        <template v-for="(subart, index) in Object.keys(stromLimits[art])">
                                            <div :key="stromLimits[art][subart].limit" 
                                                :style="{ borderBottom: index === Object.keys(stromLimits[art]).length - 1 ? 'none' : '1px solid black', padding: '8px'}"
                                            >
                                                {{  Number(stromLimits[art][subart].limit).toLocaleString('de-DE') }} €/MWh
                                            </div>                             
                                        </template>     
                                    </td>
                                    <td style="padding: 0px;">
                                        <template v-for="(subart, index) in Object.keys(stromLimits[art])">
                                            <div :key="stromLimits[art][subart].stopploss"
                                                :style="{ borderBottom: index === Object.keys(stromLimits[art]).length - 1 ? 'none' : '1px solid black', padding: '8px'}"
                                            >
                                                {{  Number(stromLimits[art][subart].stopploss).toLocaleString('de-DE') }} €/MWh
                                            </div>                             
                                        </template>  
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </v-card-text>
            </v-card>
        </div>
        <div style="margin-top: 30px; display: flex; justify-content: center;">
            <v-card style="width:100%; max-width: 800px;">
                <v-card-title>
                    <h2>Gas</h2>
                </v-card-title>
                <v-card-text>
                    <table style="box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); color: black;">
                        <thead>
                            <tr style="font-size: medium;">
                                <th>Jahr</th>
                                <th>Zeitbereiche</th>
                                <th>Limit</th>
                                <th>Stopploss</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="art in Object.keys(gasLimits)">
                                <tr :key="art">
                                    <td style="border-bottom: 2px solid black; font-size: medium;">{{ art }}</td>
                                    <td style="padding: 0px;">
                                        <template v-for="(subart, index) in Object.keys(gasLimits[art])">
                                            <div :key="subart" 
                                                :style="{ borderBottom: index === Object.keys(gasLimits[art]).length - 1 ? 'none' : '1px solid black', padding: '8px'}"
                                            >
                                                {{ subart }}
                                            </div>
                                        </template>
                                    </td>
                                    <td style="padding: 0px;">
                                        <template v-for="(subart, index) in Object.keys(gasLimits[art])">
                                            <div :key="gasLimits[art][subart].limit" 
                                                :style="{ borderBottom: index === Object.keys(gasLimits[art]).length - 1 ? 'none' : '1px solid black', padding: '8px'}"
                                            >
                                                {{  Number(gasLimits[art][subart].limit).toLocaleString('de-DE') }} €/MWh
                                            </div>                             
                                        </template>     
                                    </td>
                                    <td style="padding: 0px;">
                                        <template v-for="(subart, index) in Object.keys(gasLimits[art])">
                                            <div :key="gasLimits[art][subart].stopploss"
                                                :style="{ borderBottom: index === Object.keys(gasLimits[art]).length - 1 ? 'none' : '1px solid black', padding: '8px'}"
                                            >
                                                {{  Number(gasLimits[art][subart].stopploss).toLocaleString('de-DE') }} €/MWh
                                            </div>                             
                                        </template>  
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </v-card-text>
            </v-card>     
        </div>
   </div>
</template>

<script>
import { mapState } from "vuex"

export default {
    components: {
    },
    data() {
       return{
        items: [
            { name: "John Doe", age: 28, country: "USA" },
            { name: "Jane Smith", age: 34, country: "UK" },
        ],
       }
    },
    methods: {
       
    },
    computed: {
        ...mapState("comfortcontracts",["alleVertragsarten","vertragLimits"]),
        stromLimits() {
            const vertrage = {}
            Object.keys(this.vertragLimits).map(vertrag=> {
                if(vertrag.indexOf("Gas") < 0){
                    vertrage[vertrag] = this.vertragLimits[vertrag]
                }
            })
            return vertrage
        },
        gasLimits() {
            const vertrage = {}
            Object.keys(this.vertragLimits).map(vertrag=> {
                if(vertrag.indexOf("Gas") > 0){
                    vertrage[vertrag] = this.vertragLimits[vertrag]
                }
            })
            return vertrage
        },
    },
    watch: {
    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        await this.$store.dispatch("comfortcontracts/holeVertragsarten").then(() => {
            const years= [new Date().getFullYear()+1, new Date().getFullYear()+2]
            const arten = this.alleVertragsarten.filter(art => years.some(year => art.includes(year)))
            this.$store.dispatch("comfortcontracts/getVertragsartenLimits", arten)
        });
    }
}
</script>

<style>
.custom-table .v-data-table table tfoot th {
    position: sticky;
    bottom: 0;

}

.custom-table table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    border: 2px solid black;
    padding: 8px;
    text-align: left;
    width: 12.5%;
}
</style>

<!-- 
<div v-for="art in Object.keys(stromLimits)" :key="art" style="padding: 10px; display: flex; justify-content: space-between;">
    <h4 style="display: flex; justify-content: center; flex-flow: column;">{{ art }}</h4>
    <div style="width: 85%;">
        <div v-for="(sub, key) in  Object.keys(stromLimits[art])" :key="key" style="display: flex; justify-content: space-between;">
            <h5 style="width: 5%;">{{ sub }}</h5>
            <div style="width: 80%; display: flex">
                <div v-for="(val, key) in Object.keys(stromLimits[art][sub])" :key="key" style="width: 150px;">
                    {{ val }}: {{ stromLimits[art][sub][val] }}
                </div>
            </div>
        </div>
    </div>
</div>
-->

<!-- 
<div>
    <v-container class="bg-surface-variant mb-6" style="max-width: 800px;">
        <div style="border: .5px solid black;">
            <v-row
                align="center"
                no-gutters
                style="border: .5px solid black;"
            >
                <v-col style="height: 100%;border: .5px solid black;">
                    <v-sheet class="pa-2">
                        Jahr
                    </v-sheet>
                </v-col>
                <v-col style="height: 100%; border: .5px solid black;">
                    <v-sheet class="pa-2">
                        zeitbereiche
                    </v-sheet>
                </v-col>
                <v-col style="height: 100%; border: .5px solid black;">
                    <v-sheet class="pa-2">
                        limit
                    </v-sheet>
                </v-col>
                <v-col style="height: 100%; border: .5px solid black;">
                    <v-sheet class="pa-2">
                        stopploss
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row
                align="center"
                no-gutters
                style="height: 200px;"
                v-for="(art, index) in Object.keys(stromLimits)"
                :key="index"
            >
                <v-col v-for="n in  4" :key="n" style=" height: 100%; border: .5px solid black;">
                    <v-sheet v-if="n==1" class="pa-2" style="height: 100%; display: flex; border-bottom: .5px solid black;">
                        <v-row no-gutters align="center" style="text-align: center;">
                                {{ art }}
                        </v-row>
                    </v-sheet>
                    <v-sheet v-else-if="n === 2" style="height: -webkit-fill-available; display: flex; flex-flow: column;">
                        <v-row class="pa-2" v-for="(sub, key) in  Object.keys(stromLimits[art])" :key="key" no-gutters align="center" style="border-bottom: .5px solid black;">
                            {{ sub }}
                        </v-row>
                    </v-sheet>
                    <v-sheet v-else-if="n === 3" style="height: -webkit-fill-available; display: flex; flex-flow: column;">
                        <v-row class="pa-2" v-for="(sub, key) in  Object.keys(stromLimits[art])" :key="key" no-gutters align="center" style="border-bottom: .5px solid black;">
                                {{ stromLimits[art][sub].limit }}
                        </v-row>
                    </v-sheet>
                    <v-sheet v-else-if="n === 4"  style="height: -webkit-fill-available; display: flex; flex-flow: column;">
                        <v-row class="pa-2" v-for="(sub, key) in  Object.keys(stromLimits[art])" :key="key" no-gutters align="center" style="border-bottom: .5px solid black;">
                            {{ stromLimits[art][sub].stopploss }}
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </div>
    </v-container>
</div> 
-->
          